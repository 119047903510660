import React from 'react'
import '../styles/survey.css'

const SurveyComponent = () => {
    return (
       <iframe
          id="survey"
          src="https://x0j82wgqjm9.typeform.com/to/r96NLQ2W"
          style={{
              border: "none",
              width: "100%",
              height: "100%",
              margin: "0",
              }}></iframe>
    );
}

export default SurveyComponent
